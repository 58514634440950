<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="searchResult">
                    <v-card :elevation="1" color="grey lighten-3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-row no-gutters>
                                                <v-col cols="2">Form Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="filter.dateFrom"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="filter.dateFrom"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="text-center">To Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="filter.dateTo"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="filter.dateTo"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn height="26px" type="submit" dark block color="light-blue darken-2">Search</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <!-- <v-row dense class="pt-2" v-if="show">
            <v-col cols="12">
                <v-btn small color="grey lighten-2">Print</v-btn>
            </v-col>
        </v-row> -->
        <v-row dense v-if="show">
            <v-col cols="6">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="2">Cash In</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">Test Received</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].testReceive }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Patient Payment Received</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].patientCashReceive }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Cash Received</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].cashReceive }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Withdraw From Bank</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].bankWithdraw }}</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-bold">Total Cash In</td>
                                <td class="text-right font-weight-bold">{{ cashIn }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="6">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="2">Cash Out</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">Purchase Payment</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].purchasePayment }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Cash Payment</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].cashPayment }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Supplier Payment</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].supplierPayment }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Refferal Payment</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].commissionPayment }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Salary Payment</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].salaryPayment }}</td>
                            </tr>
                            <tr>
                                <td class="text-left">Deposit From Bank</td>
                                <td class="text-right">{{ $store.getters['cashTransaction/balanceSheet'].bankDeposit }}</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-bold">Total Cash Out</td>
                                <td class="text-right font-weight-bold">{{ cashOut }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-card color="grey darken-3" class="mt-3" v-if="show">
            <v-card-text>
                <div class="cash__balance">Cash Balance : {{ cashBalance }}</div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

export default {
    name: 'BalanceSheet',
    data: ()=> ({
        filter: {
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
        },
        show: false
    }),
    computed: {
        cashIn() {
            return (
                +this.$store.getters['cashTransaction/balanceSheet'].testReceive + 
                +this.$store.getters['cashTransaction/balanceSheet'].patientCashReceive + 
                +this.$store.getters['cashTransaction/balanceSheet'].cashReceive + 
                +this.$store.getters['cashTransaction/balanceSheet'].bankWithdraw 
            ).toFixed(2)
        },
        cashOut() {
            return (
                +this.$store.getters['cashTransaction/balanceSheet'].purchasePayment +
                +this.$store.getters['cashTransaction/balanceSheet'].cashPayment +
                +this.$store.getters['cashTransaction/balanceSheet'].supplierPayment +
                +this.$store.getters['cashTransaction/balanceSheet'].commissionPayment +
                +this.$store.getters['cashTransaction/balanceSheet'].salaryPayment +
                +this.$store.getters['cashTransaction/balanceSheet'].bankDeposit
            ).toFixed(2)
        },
        cashBalance() {
            return (+this.cashIn - +this.cashOut).toFixed(2)
        }
    },
    methods: {
        async searchResult() {
            this.show = true;
            await this.$store.dispatch('cashTransaction/getBalanceSheet', this.filter)
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px;
       top: -3px;
    }
    .theme--light.v-data-table {
        thead {
            tr {
                th {
                    border: 1px solid rgba(204, 204, 204, 0.253);
                    text-align: center !important;
                    font-size: 20px;
                    padding: 0px 1px;
                    height: 40px;
                    letter-spacing: .7px;
                    color: #000 !important;
                    font-weight: normal !important;
                }
            }
        }
    }
   .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px !important;
        padding: 1px 5px !important;
        font-size: 12px !important;
        border: 1PX solid #ccc !important;
        text-align: center;
   }
   .cash__balance {
       font-size: 18px;
       text-align: center;
       color: #ffffff;
   }
</style>